<template>
    <div class="main-container">
        <div style="margin-top:100px">
            <div>
              <img :src="logo" class="img-fluid text-center" :style="{ 'width': '50%' }"	/><br><br>
            </div>
        </div>
        <div :style="{ 'width': '100%' }">
          <form>
            <div class="form-group">
                <input v-model="otp" type="number" class="form-control" placeholder="Enter here the code you received via email" max="4">
            </div>
            <div class="form-group text-center">
              <button class="btn btn-primary login-btn" :disabled="isDisabled" @click="verifyOtp()">Verify
                  <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </form>
        </div>
    </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  name: 'Login',
  props: {
    logo: {
        type: String,
        default: "./images/lya-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      otp: null,
      email: this.$route.query.email,
    };
  },
  methods: {
    verifyOtp() {
        this.isDisabled = true;
        var data = {
            otp: this.otp,
            email: this.email
        };
        UserService.verifyOtp(data)
            .then((response) => {
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                localStorage.setItem('token', response.data.data.token);
                if (response.data.data.user.hasNewUI == true) {
                    document.location = "/kids-device";
                } else {
                    document.location = "/home";
                }
            }).catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
  },
}
</script>
