import http from "../http-common";

class UserService {
    login(data) {
        return http.post("/users/login", data);
    }
    registration(data) {
        return http.post("/users/kids/parent/register", data, {
            headers: {
                'apiKey': '0955ed5k9nHE02Xit1Kzft80A1DB2NXYE4ez'
            }
        });
    }
    userDetail() {
        return http.get("/users/detail");
    }
    verifyEmail(data) {
        return http.post("/users/verify/email", data);
    }
    kidsLogin(data) {
        return http.post("/users/kids/parent/login", data, {
            headers: {
                'apiKey': '0955ed5k9nHE02Xit1Kzft80A1DB2NXYE4ez'
            }
        });
    }
    sendForgetPasswordLink(data) {
        return http.post("/users/send-forget-password-link", data);
    }
    resetPassword(data) {
        return http.post("/users/reset-password", data);
    }
    verifyOtp(data) {
        return http.post("/users/verify-otp", data);
    }
}

export default new UserService();