<template>
    <div class="main-container">
        <div style="margin-top:100px">
            <div>
              <img :src="logo" class="img-fluid text-center" :style="{ 'width': '50%' }"	/><br><br>
            </div>
        </div>
        <div :style="{ 'width': '100%' }">
          <form>
            <div class="form-group">
                <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
            </div>
            <div class="input-group mb-3">
              <input type="password" v-model="password" class="form-control" id="password-input" placeholder="Password">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fa fa-eye-slash" aria-hidden="true" v-if="showPassword == false" @click="togglePasswordDisplay(true)"></i>
                  <i class="fa fa-eye" aria-hidden="true" v-if="showPassword == true" @click="togglePasswordDisplay(false)"></i>
                </span>
              </div>
            </div>
            <div class="form-group text-left pl-1">
              <a class="forget-pass-link" href="javascript:void(0)" @click="$router.push('/forget-password')">Forget Password?</a>
            </div>
            <div class="form-group text-center">
              <button class="btn btn-primary login-btn" :disabled="isDisabled" @click="login()">Sign in
                  <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </form>
        </div>
        <!--<div class="footer-menu-item text-center pb-4" :style=" { 'width': '90%' }">
          Don't have account? <a class="forget-pass-link" href="javascript:void(0)" @click="$router.push('/registration')"><b>Sign Up</b></a>
        </div>-->
    </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  name: 'Login',
  props: {
    logo: {
        type: String,
        default: "./images/lya-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      email: null,
      password: null,
      userDetail: null,
      showPassword: false,
    };
  },
  methods: {
    async login() {
      this.isDisabled = true;
      let data = {
        email: this.email,
        password: this.password,
        userAgent: navigator.userAgent
      };
      UserService.login(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            if (response.data.enable_two_step_email_verification) {
              this.$router.push({ path: '/verify-otp', query: { email: this.email }});
            } else {
              this.email = null;
              this.password = null;
              localStorage.setItem('token', response.data.data.token);
              //this.$router.push({ path: '/home'})
              if (response.data.data.user.hasNewUI == true) {
                document.location = "/kids-device";
              } else {
                document.location = "/home";
              }
            }
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async verifyEmail(iv, encryptedData) {
      this.isDisabled = true;
      let data = {
        iv: iv,
        encryptedData: encryptedData,
      };
      await UserService.verifyEmail(data)
        .then((response) => {
          //this.userDetail = response.data.data;
          localStorage.setItem('token', response.data.data.token);
          //this.$router.push({ path: '/home'})
          document.location = "/home";
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    togglePasswordDisplay(showPassword) {
      if (showPassword == true) {
        $('#password-input').attr('type', 'text');
      } else {
        $('#password-input').attr('type', 'password');
      }
      this.showPassword = showPassword;
    }
  },
  beforeMount() {
    if (this.$route.query.iv && this.$route.query.encryptedData) {
      this.verifyEmail(this.$route.query.iv,this.$route.query.encryptedData);
    }
  },
}
</script>
