<template>
    <div class="main-container">
        <div style="margin-top:100px">
            <div>
              <img :src="logo" class="img-fluid text-center" :style="{ 'width': '50%' }"	/><br><br>
            </div>
        </div>
        <div :style="{ 'width': '100%' }">
          <form>
            <div class="form-group">
                <input type="name" v-model="name" class="form-control" placeholder="Parent Name">
            </div>
            <div class="form-group">
                <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">
            </div>
            <div class="input-group mb-3">
              <input type="password" v-model="password" class="form-control" id="password-input" placeholder="Password">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fa fa-eye-slash" aria-hidden="true" v-if="showPassword == false" @click="togglePasswordDisplay(true)"></i>
                  <i class="fa fa-eye" aria-hidden="true" v-if="showPassword == true" @click="togglePasswordDisplay(false)"></i>
                </span>
              </div>
            </div>
            <div class="form-group text-center">
              <button class="btn btn-primary login-btn" :disabled="isDisabled" @click="registration()">Sign up
                  <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </form>
        </div>
        <div class="footer-menu-item text-center pb-4" :style=" { 'width': '90%' }">
          Already have account? <a class="login" href="javascript:void(0)" @click="$router.push('/login')"><b>Login</b></a>
        </div>
    </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  name: 'Login',
  props: {
    logo: {
        type: String,
        default: "./images/lya-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      name: null,
      email: null,
      password: null,
      userDetail: null,
      showPassword: false,
    };
  },
  methods: {
    async registration() {
      this.isDisabled = true;
      let data = {
        email: this.email,
        password: this.password,
        userAgent: navigator.userAgent,
        parentName: this.name
      };
      UserService.registration(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.name = "";
            this.email = "";
            this.password = "";
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    togglePasswordDisplay(showPassword) {
      if (showPassword == true) {
        $('#password-input').attr('type', 'text');
      } else {
        $('#password-input').attr('type', 'password');
      }
      this.showPassword = showPassword;
    }
  },
  beforeMount() {
  },
}
</script>
