<template>
    <div class="profile-section text-center pt-2">
        <h5 class="text-left" >
            <label class="underline">
            <a href="mailto:fvw@forestrock.ch" class="text-black"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_231_967)">
            <path d="M17.8 15.4H9.4L4.6 19V15.4H2.2C1.88174 15.4 1.57652 15.2736 1.35147 15.0485C1.12643 14.8235 1 14.5183 1 14.2V2.2C1 1.88174 1.12643 1.57652 1.35147 1.35147C1.57652 1.12643 1.88174 1 2.2 1H17.8C18.1183 1 18.4235 1.12643 18.6485 1.35147C18.8736 1.57652 19 1.88174 19 2.2V14.2C19 14.5183 18.8736 14.8235 18.6485 15.0485C18.4235 15.2736 18.1183 15.4 17.8 15.4Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.59961 5.80006C7.59969 5.36008 7.72072 4.92859 7.94947 4.55275C8.17821 4.17691 8.50587 3.87118 8.89663 3.66898C9.28739 3.46678 9.72622 3.37588 10.1651 3.40623C10.6041 3.43658 11.0262 3.587 11.3854 3.84105C11.7447 4.09511 12.0271 4.44302 12.202 4.84677C12.3768 5.25051 12.4373 5.69455 12.3768 6.13035C12.3164 6.56615 12.1373 6.97695 11.8591 7.31784C11.5809 7.65873 11.2144 7.9166 10.7996 8.06326C10.5655 8.14601 10.3629 8.29932 10.2196 8.50206C10.0764 8.7048 9.99948 8.94699 9.99961 9.19526V9.40006" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.99922 12.4C9.91965 12.4 9.84335 12.3684 9.78709 12.3122C9.73083 12.2559 9.69922 12.1796 9.69922 12.1C9.69922 12.0205 9.73083 11.9442 9.78709 11.8879C9.84335 11.8317 9.91965 11.8 9.99922 11.8" stroke="#333333" stroke-width="1.5"/>
            <path d="M10 12.4C10.0796 12.4 10.1559 12.3684 10.2121 12.3122C10.2684 12.2559 10.3 12.1796 10.3 12.1C10.3 12.0205 10.2684 11.9442 10.2121 11.8879C10.1559 11.8317 10.0796 11.8 10 11.8" stroke="#333333" stroke-width="1.5"/>
            </g>
            <defs>
            <clipPath id="clip0_231_967">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
            </svg>

     
            Need Help?</a></label>
        </h5>
        <div class="text-left">
            <a class="pr-3 text-black" href="https://docs.google.com/document/d/168sPHF68dsXINSZntdDL8K8N6sUPePFAxtSIYq8BumQ/edit" target="_blank">Beta Tester Briefing</a>
            <a class="pr-3 text-black" href="https://docs.google.com/document/d/1ZtmAJNTUS85_CFPl_1iA8bXnUk1kLJsFOcuSlDSiFsA/edit?tab=t.0#heading=h.tt920hpeu0e1" target="_blank">Media Competence for Children</a>
        </div>
    </div>
    <div class="profile-section text-left pt-4 bg-dark">
        <span class="text-white pl-2">LYA Phone 2024. All rights reserved</span><hr style="border-top: 1px solid rgb(251 246 246 / 10%);">
        <span class="text-white pl-2">Privacy Policy</span>
    </div>

</template>

<script>
export default {
  name: 'KidFooter',
  props: {
    profileDetail: Object
  },
  methods: {
  }
}
</script>
