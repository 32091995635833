import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import Registration from '../views/Registration.vue'
import otpVerification from '../views/OtpVerification.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Home from '../views/Home.vue'
import Applications from '../views/Applications.vue'
import WebSettings from '../views/WebSettings.vue'
import DeviceDetail from '../views/DeviceDetail.vue'
import Restrictions from '../views/Restrictions.vue'
import ScreenTime from '../views/ScreenTime.vue'
import Settings from '../views/Settings.vue'
import Contacts from '../views/Contacts.vue'
import SecureBrowser from '../views/SecureBrowser.vue'
import Wipe from '../views/Wipe.vue'
import SecureChat from '../views/SecureChat.vue'

//New Kids
import DeviceDetailPage from '../views/new/DeviceDetail.vue'
import ApplicationDetail from '../views/new/ApplicationDetail.vue'
import CategoryDetail from '../views/new/CategoryDetail.vue'
import ApplicationList from '../views/new/ApplicationList.vue'

const routes = [
  {
    path: "/",
    component: Login,
    name: "Before Auth",
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
    ]
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/verify-otp',
    name: 'OTP Verification',
    component: otpVerification,
  },
  {
    path: '/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/apps',
    name: 'Applications',
    component: Applications
  },
  {
    path: '/web',
    name: 'Webs Settings',
    component: WebSettings
  },
  {
    path: '/device',
    name: 'Device Detail',
    component: DeviceDetail
  },
  {
    path: '/restrictions',
    name: 'Restrictions',
    component: Restrictions
  },
  {
    path: '/screen-time',
    name: 'Screen Time',
    component: ScreenTime
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/secure-browsers',
    name: 'Secure Browser',
    component: SecureBrowser
  },
  {
    path: '/wipe',
    name: 'Wipe',
    component: Wipe
  },
  {
    path: '/secure-chat',
    name: 'Secure Chat',
    component: SecureChat
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },

  // New Kids Layout
  {
    path: '/kids-device',
    name: 'Kids Devices',
    component: DeviceDetailPage,
  },
  {
    path: '/application',
    name: 'Application',
    component: ApplicationDetail,
  },
  {
    path: '/category',
    name: 'Category',
    component: CategoryDetail,
  },
  {
    path: '/play-store',
    name: 'Applications List',
    component: ApplicationList,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

import UserService from "../services/UserService";
// GOOD
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  if (isAuthenticated) {
    await UserService.userDetail()
    .then((response) => {
      }).catch((e) => {
        localStorage.setItem('token', "");
        next({ name: 'Login' })
      });
  }
  if (to.name == 'Forget Password' || to.name == 'Registration' || to.name == 'OTP Verification' || to.name == 'Reset Password') {
    next()
  } else {
    if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    else if (to.name == 'Login' && isAuthenticated) next({ name: 'Home' })
    else next()
  }
})

export default router
