<template>
    <div class="main-container">
        <div style="margin-top:100px">
            <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="this.show_message">
                <strong>Congratulation!</strong> A message with a reset password link has been sent to your email address. Please open the link to set your new password.
            </div><br>
            <img :src="logo" class="img-fluid text-center" :style="{ 'width': '50%' }"	/><br><br>
        </div>
        <div :style="{ 'width': '100%' }">
            <form>
                <div class="form-group">
                    <input type="email" v-model="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                </div>
                <div class="form-group text-center">
                    <button class="btn btn-primary login-btn" :disabled="isDisabled" @click="sendForgetPasswordLink()">Send
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </form>
        </div>
        <div class="footer-menu-item text-center pb-4" :style=" { 'width': '90%' }">
          Already have account? <a class="forget-pass-link" href="javascript:void(0)" @click="$router.push('/login')"><b>Login</b></a>
        </div>
    </div>
</template>

<script>
import UserService from "../services/UserService";
export default {
  name: 'Login',
  props: {
    logo: {
        type: String,
        default: "./images/lya-logo.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      email: null,
      show_message: false,
    };
  },
  methods: {
    async sendForgetPasswordLink() {
        this.isDisabled = true;
        let data = {
            email: this.email,
        };
        await UserService.sendForgetPasswordLink(data)
            .then((response) => {
                this.isDisabled = false;
                this.$toast.success(response.data.respones_msg);
                this.email = null;
                this.show_message = true;
            })
            .catch((e) => {
                this.isDisabled = false;
                this.$toast.error(e.response.data.respones_msg);
            });
    },
  },
  beforeMount() {
    
  },
}
</script>
